<template>
  <div class="main-container">
    <div class="main-header">
      <div class="icon-left"></div>
      <div style="font-size: 16px; font-weight: 800">
        {{ cardsObj.standardContent }}
      </div>
      <div style="margin-left: 12px">
        <div
          v-if="cardsObj.standardType === 2"
          class="passStatusCss"
          :style="{
            backgroundColor:
              cardsObj.passStatus === '1000'
                ? '#409EFF'
                : cardsObj.passStatus === '1002'
                ? '#f90a0a'
                : '#fca506',
          }"
        >
          <div v-if="cardsObj.standardType === 2">
            {{
              cardsObj.passStatus === '1000'
                ? '通过'
                : cardsObj.passStatus === '1002'
                ? '不通过'
                : '待检测'
            }}
          </div>
        </div>
        <div
          v-else
          class="passStatusCss"
          :style="{
            backgroundColor:
              cardsObj.passStatus === '2000' || cardsObj.passStatus === '3000'
                ? '#409EFF'
                : cardsObj.passStatus === '2002' ||
                  cardsObj.passStatus === '3002'
                ? '#f90a0a'
                : '#fca506',
          }"
        >
          <div
            v-if="
              cardsObj.passStatus === '2000' || cardsObj.passStatus === '3000'
            "
          >
            通过
          </div>
          <div v-else>
            <span>
              {{ cardsObj.standardType === 3 ? '扣分/总分' : '得分/总分' }}
            </span>
            <span>{{ cardsObj.standardResult }}</span
            >/{{ cardsObj.standardScore }}
          </div>
        </div>
      </div>
    </div>
    <div class="main-bottom">
      <div
        v-for="(item, index) in cardsObjChildren"
        :key="index"
        class="crad-list"
      >
        <div class="crad-list-left">
          <div>{{ item.standardContent }}</div>
          <div v-if="item.auditType === '2'">
            <el-button type="primary" @click="openDrawer(item, 'artificial')">
              {{
                item.passStatus === '1001' ||
                item.passStatus === '2001' ||
                item.passStatus === '3001'
                  ? '人工审核'
                  : '人工复核'
              }}</el-button
            >
          </div>
        </div>
        <div class="crad-list-right">
          <div class="passStatus-title">
            <div
              v-if="item.standardType === 2"
              class="passStatusCss"
              :style="{
                backgroundColor:
                  item.passStatus === '1000'
                    ? '#409EFF'
                    : item.passStatus === '1002'
                    ? '#f90a0a'
                    : '#fca506',
              }"
            >
              {{
                item.passStatus === '1000'
                  ? '通过'
                  : item.passStatus === '1002'
                  ? '不通过'
                  : '待检测'
              }}
            </div>
            <div
              v-if="item.standardType === 3"
              class="passStatusCss"
              :style="{
                backgroundColor:
                  item.passStatus === '2000'
                    ? '#409EFF'
                    : item.passStatus === '2002'
                    ? '#f90a0a'
                    : '#fca506',
              }"
            >
              {{
                item.passStatus === '2000'
                  ? '通过'
                  : item.passStatus === '2002'
                  ? `- ${item.standardResult}&nbsp;&nbsp;分`
                  : '待检测'
              }}
            </div>
            <div
              v-if="item.standardType === 4"
              class="passStatusCss"
              :style="{
                backgroundColor:
                  item.passStatus === '3000'
                    ? '#409EFF'
                    : item.passStatus === '3002'
                    ? '#67C23A'
                    : '#fca506',
              }"
            >
              {{
                item.passStatus === '3000'
                  ? '通过'
                  : item.passStatus === '3002'
                  ? `+ ${item.standardResult}&nbsp;&nbsp;分`
                  : '待检测'
              }}
            </div>
          </div>
          <div>
            <div>
              <el-button
                type="text"
                style="margin-right: 20px"
                v-if="item.auditType !== '4'"
                :disabled="item.auditType === '2' && item.passStatus === '1000'"
                @click="openDrawer(item, 'details')"
                >详情</el-button
              >
              <span>{{ item.updateTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      :title="drawerTitle"
      :visible.sync="drawer"
      v-if="drawer"
      :wrapper-closable="false"
      size="50%"
    >
      <!-- 第一种情况，表格形式(详情) -->
      <div v-if="drawerIf === 'details'">
        <!-- 机器+人工 -->
        <div
          class="bd-content"
          v-if="machinePlusHuman"
          style="padding: 0 20px 20px 20px"
        >
          <div style="padding-bottom: 10px; display: flex">
            <div>
              <el-button
                type="primary"
                @click="handleBatch(2)"
                :disabled="searchParams1.auditStatus === 2"
              >
                <span> 一键确认</span>
              </el-button>
              <el-button
                type="primary"
                @click="handleBatch(3)"
                :disabled="searchParams1.auditStatus === 3"
              >
                <span> 一键忽略</span>
              </el-button>
            </div>
            <table-search
              :model="searchParams1"
              label-width="80px"
              :isClear="false"
              @search="searchFn"
            >
              <search-item style="width: 50%" default label="">
                <el-select
                  size="small"
                  v-model="searchParams1.auditStatus"
                  placeholder="审核状态"
                >
                  <el-option
                    v-for="unit in statusList"
                    :key="unit.value"
                    :label="unit.label"
                    :value="unit.value"
                  />
                </el-select>
              </search-item>
            </table-search>
          </div>
          <pageTable
            ref="table"
            :params="searchParams1"
            :api-function="$api.surveyData.getArticlecheckList"
            row-key="monitorId"
            @cell-click="cellClickFn"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              label="序号"
              fixed
              type="index"
              prop="index"
              width="55"
            />

            <template v-for="(item, index) in column">
              <el-table-column
                v-if="!item.hidden"
                :key="item.key || item.prop || index"
                v-bind="item"
              >
                <template slot-scope="scope">
                  <template v-if="item.prop === 'content'">
                    <span
                      class="hiddenContent"
                      :title="scope.row[item.prop]"
                      v-html="showContextContent_html(scope.row)"
                    ></span>
                  </template>
                  <template v-else-if="item.prop === 'articleUrl'">
                    <a
                      @click="openWin(scope.row.articleUrl)"
                      style="cursor: pointer"
                      >{{ scope.row.articleUrl }}</a
                    >
                  </template>

                  <template v-else-if="item.prop === 'auditStatus'">
                    <span v-if="scope.row.auditStatus === 1">未审核</span>
                    <span v-else-if="scope.row.auditStatus === 2">已确认</span>
                    <span v-else-if="scope.row.auditStatus === 3">已忽略</span>
                  </template>
                  <template v-else>
                    {{ scope.row[item.prop] }}
                  </template>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="复核" width="100px" fixed="right">
              <!-- v-if="scope.row.auditStatus == 1" -->
              <template slot-scope="scope">
                <div class="data-operate">
                  <span
                    class="op-green"
                    @click="customIgnoreCount(scope.row, 2)"
                  >
                    确认
                  </span>
                  <span class="op-red" @click="customIgnoreCount(scope.row, 3)">
                    忽略
                  </span>
                </div>
              </template>
            </el-table-column>
          </pageTable>
        </div>
        <!-- 机器与人工-->
        <div class="bd-content" v-else style="padding: 0 20px 20px 20px">
          <div v-if="historyPeople">
            人工历史
            <el-button @click="historyDetail">详情</el-button>
          </div>
          <pageTable
            row-key="orderId"
            :columns="columnList"
            :params="searchParams"
            :api-function="requestApi"
            :hiddenPage="hiddenPage"
            v-else
          >
            <!-- 扣分-IPv6改造1 -->
            <template slot="extInfo" slot-scope="scope">
              <span v-if="scope.row.extInfo">通过</span>
              <span v-else>不通过</span>
            </template>
            <!-- url 跳转 -->
            <template slot="belongHtmlUrl" slot-scope="scope">
              <a
                @click="openWin(scope.row.belongHtmlUrl)"
                style="cursor: pointer"
                >{{ scope.row.belongHtmlUrl }}</a
              >
            </template>
            <template slot="url" slot-scope="scope">
              <a @click="openWin(scope.row.url)" style="cursor: pointer">{{
                scope.row.url
              }}</a>
            </template>
            <template slot="extInfo.url" slot-scope="scope">
              <a
                @click="openWin(scope.row.extInfo.url)"
                style="cursor: pointer"
                >{{ scope.row.extInfo.url }}</a
              >
            </template>
            <template slot="siteUrl" slot-scope="scope">
              <a @click="openWin(scope.row.siteUrl)" style="cursor: pointer">{{
                scope.row.siteUrl
              }}</a>
            </template>
            <template slot="agencyHomeUrl" slot-scope="scope">
              <a
                @click="openWin(scope.row.agencyHomeUrl)"
                style="cursor: pointer"
                >{{ scope.row.agencyHomeUrl }}</a
              >
            </template>
            <!-- 快照显示 -->
            <template slot="images" slot-scope="scope">
              <el-image
                v-for="(item, index) in scope.row.images"
                :key="index"
                style="width: 40px; height: 40px; margin-left: 4px"
                fit="contain"
                :src="item"
                :preview-src-list="[...scope.row.images]"
              >
              </el-image>
            </template>
            <template slot="manualImg" slot-scope="scope">
              <el-image
                v-for="(item, index) in scope.row.manualImg"
                :key="index"
                style="width: 40px; height: 40px; margin-left: 4px"
                :src="item"
                :preview-src-list="[...scope.row.manualImg]"
              >
              </el-image>
            </template>
          </pageTable>
        </div>
      </div>
      <!-- 第二种情况，表单提交形式（人工监测） -->
      <div v-else>
        <div
          style="
            padding: 10px 20px 10px 10px;
            width: 100%;
            height: 100%;
            background-color: #fff;
          "
        >
          <el-radio-group
            v-model="form.monitorStatus"
            style="padding-bottom: 20px; margin-left: 84px"
            :fill="
              drawerObj.standardType === 4 && form.monitorStatus === 1
                ? '#67C23A'
                : drawerObj.standardType === 3 && form.monitorStatus === 1
                ? '#F56C6C'
                : '#409EFF'
            "
          >
            <el-radio-button :label="0">通&nbsp;&nbsp;&nbsp;过</el-radio-button>
            <el-radio-button :label="1">
              {{
                drawerObj.standardType === 2
                  ? '不通过'
                  : drawerObj.standardType === 3
                  ? '扣&nbsp;&nbsp;&nbsp;分'
                  : '加&nbsp;&nbsp;&nbsp;分'
              }}
            </el-radio-button>
          </el-radio-group>
          <el-form
            :model="form"
            ref="form"
            label-width="85px"
            label-position="right"
            v-show="form.monitorStatus === 1"
          >
            <el-form-item
              :label="drawerObj.standardType === 3 ? '扣分：' : '加分：'"
              prop="score"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'blur',
                },
              ]"
              v-if="drawerObj.standardType !== 2"
            >
              <el-input-number
                v-model="form.score"
                :min="Number(drawerObj.frequencyScore)"
                :max="Number(drawerObj.standardScore)"
                :step="Number(drawerObj.frequencyScore)"
              ></el-input-number>
            </el-form-item>
            <div
              v-for="(item, index) in form.list"
              :key="index"
              style="display: flex"
              :class="hiddenItemArr.includes(drawerObj.id) ? 'borderClass' : ''"
            >
              <div style="width: 85%; margin-top: 10px">
                <el-form
                  :model="form.list[index]"
                  :ref="'formValueInfo' + `${index}`"
                  label-width="85px"
                  :rules="rules"
                  label-position="right"
                >
                  <el-form-item
                    :label="drawerObj.id === 123 ? '浏览器名称' : '问题链接：'"
                    prop="valueInfo"
                    style="margin-top: 10px"
                    v-if="hiddenItemArr.includes(drawerObj.id)"
                  >
                    <el-input v-model="item.valueInfo"></el-input>
                  </el-form-item>
                </el-form>
                <el-form-item label="图片：">
                  <uploadimage
                    @input="getSonInput($event, index)"
                  ></uploadimage>
                </el-form-item>
              </div>
              <div
                style="
                  width: 150px;
                  text-align: left;
                  margin-left: 20px;
                  margin-top: 25px;
                "
                v-if="
                  hiddenItemArr.includes(drawerObj.id) && drawerObj.id !== 119
                "
              >
                <i
                  class="el-icon-delete"
                  style="font-size: 22px; color: #f56c6c; cursor: pointer"
                  @click="delItem(index)"
                ></i>
                <i
                  class="el-icon-plus"
                  style="
                    margin-left: 20px;
                    font-size: 22px;
                    color: #409eff;
                    cursor: pointer;
                  "
                  v-if="index + 1 === form.list.length"
                  @click="addItem"
                ></i>
              </div>
            </div>
            <el-form-item label="备注：">
              <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button type="primary" @click="onSubmit('formValueInfo')"
              >提交</el-button
            >
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import uploadimage from '@/components/uploadimage/index.vue';

export default {
  name: 'orderCardList',
  components: {
    uploadimage,
  },
  data() {
    return {
      // 页面obj
      cardsObj: {},
      // 页面下三层指标
      cardsObjChildren: [],
      // 抽屉
      drawer: false,
      // 抽屉title
      drawerTitle: '',
      //  抽屉obj
      drawerObj: {},
      hiddenItemArr: [14, 114, 118, 119, 123],
      hiddenPageArr: [14, 113, 114, 118, 119, 123],
      // 抽屉表格搜索 人工和机器
      searchParams: {
        // 特殊处理extInfo字段，给组件判断提供
        special: 'orderCardList',
        orderCode: '',
        standardId: null,
        standardDataId: null,
      },
      searchParams1: {
        orderCode: '',
        standardId: null,
        standardDataId: null,
        // 审核状态：1-待审核，2-已确认，3-已忽略，默认值：1
        auditStatus: 1,
      },
      statusList: [
        { label: '未审核', value: 1 },
        { label: '已确认', value: 2 },
        { label: '已忽略', value: 3 },
      ],
      form: {
        // 监测结果：0-通过，1-不通过
        standardDataId: null,
        orderCode: '',
        standardId: null,
        monitorStatus: 1,
        score: null,
        remark: null,
        list: [
          {
            valueInfo: null,
            images: [],
          },
        ],
      },
      // 抽屉表格列表
      columnList: [],
      // 抽屉表格列表-api
      requestApi: null,
      // 抽屉内容两种情况判断
      drawerIf: '',
      // 机器人工表格和普通判断
      machinePlusHuman: false,
      // 单项-安全、泄密事故等严重问题4,扣分-站内搜索2、3，扣分-域名不符合规范1,扣分-域名名称1  不显示分页
      hiddenPage: false,
      // 人工历史
      historyPeople: false,
      // 详情人工和机器对象数组集
      columnLists: [
        // 机器详情表格
        // 单项
        {
          // 单项-网站监测-安全、泄密事故等严重问题5
          id: [15],
          columnList: [
            {
              label: '栏目页发布时间',
              prop: 'publishTimeList',
            },
            {
              label: '内容页发布时间',
              prop: 'publishTime',
            },
            {
              label: '应间隔时间',
              prop: 'standardDuration',
            },
            {
              label: '间隔时间',
              prop: 'nonStandardDuration',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '栏目页链接',
              prop: 'channelUrl',
            },
            {
              label: '内容页链接',
              prop: 'articleUrl',
            },
          ],
        },
        {
          // 单项-网站监测-站点无法访问1
          id: [18],
          columnList: [
            {
              label: '链接',
              prop: 'agencyHomeUrl',
              show: 'template',
            },
            {
              label: '链接状态',
              prop: 'monitoringStatusDesc',
            },
            {
              label: 'http状态码',
              prop: 'statusCode',
            },
            {
              label: '状态码描述',
              prop: 'statusCnMessage',
            },
            {
              label: '耗时（ms）',
              prop: 'grabType',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
          ],
        },
        {
          // 单项-网站监测-首页不更新-1
          id: [19],
          columnList: [
            {
              label: '未更新时长',
              prop: 'nonStandardDuration',
            },
            {
              label: '应更新时长',
              prop: 'standardDuration',
            },
            {
              label: '最近更新时间',
              prop: 'lastPublishTime',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
          ],
        },
        {
          // 单项-网站监测-栏目不更新1、2
          id: [22, 23],
          columnList: [
            {
              label: '栏目名称',
              prop: 'channelName',
            },
            {
              label: '栏目类型',
              prop: 'channelTypeDesc',
            },
            {
              label: '未更新时长',
              prop: 'nonStandardDuration',
            },
            {
              label: '应更新时长',
              prop: 'standardDuration',
            },
            {
              label: '最近更新时间',
              prop: 'lastPublishTime',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
          ],
        },
        {
          // 单项-网站监测-栏目不更新-3，扣分-发布解读-其他栏目1
          id: [24, 60],
          columnList: [
            {
              label: '栏目名称',
              prop: 'channelName',
            },
            {
              label: '栏目类型',
              prop: 'channelTypeDesc',
            },
            {
              label: '是否空栏目',
              prop: 'delFlag',
            },
            {
              label: '链接',
              prop: 'channelUrl',
              show: 'template',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
          ],
        },
        // 扣分
        {
          // 扣分-发布解读-动态要闻1,政策文件1,政策解读1
          id: [50, 52, 54],
          columnList: [
            {
              label: '监测结果',
              prop: 'monitoringStatusDesc',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
          ],
        },
        {
          // 扣分-发布解读-动态要闻2,政策文件2,政策解读2,其他栏目2，加分-数据发布1
          id: [51, 53, 55, 61, 133],
          columnList: [
            {
              label: '栏目名称',
              prop: 'channelName',
            },
            {
              label: '栏目类型',
              prop: 'channelTypeDesc',
            },
            {
              label: '未更新时长',
              prop: 'nonStandardDuration',
            },
            {
              label: '应更新时长',
              prop: 'standardDuration',
            },
            {
              label: '最近更新时间',
              prop: 'lastPublishTime',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
          ],
        },
        // 人工详情表格
        {
          //扣分-- 概况信息1、2,机构职能1、2,领导信息1、2,解读比例1,解读关联1
          id: [42, 43, 45, 46, 48, 49, 56, 58],
          columnList: [
            {
              label: '序号',
              type: 'index',
              width: 50,
            },
            {
              label: '监测结果',
              prop: 'monitoringStatusDesc',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'manualImg',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          // 扣分-域名名称1
          id: [107],
          columnList: [
            {
              label: '域名',
              prop: 'extInfo.url',
              show: 'template',
            },
            {
              label: '域名是否规范',
              prop: 'monitoringStatusDesc',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'manualImg',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          // 扣分-域名名称2,网站标识1
          id: [108, 110],
          columnList: [
            {
              label: '网站名称',
              prop: 'extInfo.siteName',
            },
            {
              label: '是否规范',
              prop: 'monitoringStatusDesc',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'manualImg',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          // 扣分-域名名称3
          id: [109],
          columnList: [
            {
              label: '网站头部是否展示网站名称',
              prop: 'monitoringStatusDesc',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'manualImg',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          // 扣分-可用性1、2
          id: [111, 112],
          columnList: [
            {
              label: '问题链接',
              prop: 'belongHtmlUrl',
              show: 'template',
            },
            {
              label: '链接类型',
              prop: 'linkOwnershipDesc',
            },
            {
              label: 'http状态码',
              prop: 'statusCode',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          // 扣分-我为网站找错误1
          id: [113],
          columnList: [
            {
              label: '链接',
              prop: 'extInfo.url',
              show: 'template',
            },
            {
              label: '页面类型',
              prop: 'extInfo.pageTypeDesc',
            },
            {
              label: '是否标志规范',
              prop: 'monitoringStatusDesc',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'manualImg',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          //扣分-我为网站找错误2
          id: [114],
          columnList: [
            {
              label: '链接',
              prop: 'url',
              show: 'template',
            },
            {
              label: '页面类型',
              prop: 'pageTypeDesc',
            },
            {
              label: '是否标志规范',
              prop: 'monitoringStatusDesc',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'images',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          // 扣分-我为网站找错误3、4
          id: [115, 116,120],
          columnList: [
            {
              label: '是否合格',
              prop: 'monitoringStatusDesc',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'manualImg',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          // 扣分-站内搜索1
          id: [117],
          columnList: [
            {
              label: '搜索是否可用',
              prop: 'monitoringStatusDesc',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'manualImg',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          //单项-安全、泄密事故等严重问题4,扣分-站内搜索2
          id: [14, 118],
          columnList: [
            {
              label: '问题链接',
              prop: 'url',
              show: 'template',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'images',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          //扣分-站内搜索3
          id: [119],
          columnList: [
            {
              label: '问题链接',
              prop: 'extInfo.url',
              show: 'template',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'manualImg',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        // 一号登录 -不检测
        {
          // 扣分-页面标签1.
          id: [121],
          columnList: [
            {
              label: ' 问题链接',
              prop: 'siteUrl',
              show: 'template',
            },
            {
              label: ' 文章标题',
              prop: 'siteTitle',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          // 扣分-页面标签2
          id: [122],
          columnList: [
            {
              label: ' 问题链接',
              prop: 'siteUrl',
              show: 'template',
            },
            {
              label: ' 栏目名称',
              prop: 'channelName',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          // 扣分-兼容性1
          id: [123],
          columnList: [
            {
              label: '浏览器名称',
              prop: 'browserName',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'images',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        {
          // 扣分-IPv6改造1
          id: [124],
          columnList: [
            {
              label: ' 是否完成',
              prop: 'extInfo',
              show: 'template',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'manualImg',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
        // 加分
        {
          id: [
            134, 135, 136, 140, 141, 142, 143, 146, 147, 148, 151, 152, 153,
            154, 156,
          ],
          columnList: [
            {
              label: '加分',
              prop: 'monitoringStatusDesc',
            },
            {
              label: '分数',
              prop: 'score',
            },
            {
              label: '监测时间',
              prop: 'createTime',
            },
            {
              label: '快照',
              prop: 'manualImg',
              show: 'template',
            },
            {
              label: '备注',
              prop: 'remark',
            },
          ],
        },
      ],
      // 人工+机器详情
      column: [
        { label: '错误表述', prop: 'content', width: '600' },

        {
          label: '文章链接',
          prop: 'articleUrl',
          showOverflowTooltip: true,
          isDisabled: true,
        },
        {
          label: '监测时间',
          prop: 'createTime',
          width: '130',
          showOverflowTooltip: true,
          isDisabled: true,
        },
        {
          label: '审核状态',
          prop: 'auditStatus',
          showOverflowTooltip: true,
        },
      ],
      batchList: [],
      rules: {
        valueInfo: [
          { required: true, message: '该输入项为必填项!', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    // 获取指标lsit
    getOrderTreeList() {
      let obj = {
        orderCode: window.sessionStorage.getItem('orderCode'),
        parentId: this.$route.query.parentId,
      };
      this.$api.surveyData.getOrderTreeList(obj).then((res) => {
        if (res.code === 0) {
          this.cardsObj = res.data;
          this.cardsObjChildren = this.cardsObj.children.filter(function (
            item
          ) {
            return item.auditType !== '5';
          });
          this.$route.meta.title = res.data.standardContent;
        }
      });
    },
    searchFn() {
      this.$refs.table.getPageDataOfAction('search');
    },
    cellClickFn(row, column) {
      const field = column.property;
      // 弹窗
      if (['content'].indexOf(field) > -1) {
        if (!row.contextContent_html) {
          this.setContextContent_html(row);
        }
        this.$alert(row['contextContent' + '_html'], '详情', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '关闭',
          customClass: 'contextModify',
          closeOnPressEscape: true,
        }).then(
          (res) => {},
          (err) => {}
        );
      }
    },
    setContextContent_html(row = {}) {
      // TODO: 目前先假设 建议值只有一个，上下文中可能有多个匹配，但只有一个目标词（依据偏移量）
      /**
       * offset 偏移量属性正确
       * - 根据便宜量能找到 错词
       */
      let offsetRight = false;
      /** 识别词 偏移量 */
      const offsetOri = row.beginIndex;
      /** 识别词 偏移量 */
      const offset = offsetOri >= 0 ? offsetOri : 0;

      /** 识别问题词 */
      const ce = row.contextError;
      /** 建议修改词 */
      const cr = row.contextRight || '';
      /** @type {string} 上下文 */
      let content = row.content;
      /** 识别词 与 建议词 是否相同 */
      const isEqual = ce === cr;
      const error =
        '<span class="contextError' +
        (isEqual ? 'isEqual' : '') +
        '">' +
        ce +
        '</span>';
      const suggest =
        isEqual || !cr ? '' : '<span class="contextSuggest">' + cr + '</span>';

      let startStr = content.substr(0, offset);
      let endStr = content.substring(offset);
      let targetStr = '';
      if (endStr.startsWith(ce)) {
        endStr = endStr.substring(ce.length);
        targetStr = [error, suggest].join('');
        offsetRight = true;
      } else {
        offsetRight = false;
      }

      // 处理其他相似字段
      startStr = startStr.replaceAll(
        ce,
        '<span class="alikeStr">' + ce + '</span>'
      );
      endStr = endStr.replaceAll(
        ce,
        '<span class="alikeStr">' + ce + '</span>'
      );
      // (offsetRight ? '' : '【偏移量未识别错词】') +
      let html = '';
      if (!offsetRight) {
        html += '【偏移量未识别错词】';
        if (offsetOri == -1) {
          html = '';
        }
      }
      if (offsetOri != offset) {
        html += '【上下文未找到错词】' + [error, suggest].join('');
      }

      html =
        (html ? html + '<br/>' : '') + startStr + targetStr + endStr + '1111';

      row.contextContent_html = html;
    },
    showContextContent_html: function (row = {}) {
      // TODO: 目前先假设 建议值只有一个，上下文中可能有多个匹配，但只有一个目标词（依据偏移量）
      /**
       * offset 偏移量属性正确
       * - 根据便宜量能找到 错词
       */
      let offsetRight = false;
      /** 识别词 偏移量 */
      const offsetOri = row.beginIndex;
      /** 识别词 偏移量 */
      const offset = offsetOri >= 0 ? offsetOri : 0;
      /** 识别问题词 */
      const ce = row.contextError;
      /** 建议修改词 */
      const cr = row.contextRight || '';
      /** @type {string} 上下文 */
      let content = row.content;
      /** 识别词 与 建议词 是否相同 */
      const isEqual = ce === cr;
      const error =
        '<span class="contextError' +
        (isEqual ? 'isEqual' : '') +
        '">' +
        ce +
        '</span>';
      let startStr = content.substr(0, offset);
      let endStr = content.substring(offset);
      let targetStr = '';
      if (endStr.startsWith(ce)) {
        endStr = endStr.substring(ce.length);
        targetStr = [error].join('');
        offsetRight = true;
      } else {
        offsetRight = false;
      }
      if (ce !== '') {
        // 处理其他相似字段
        startStr = startStr.replaceAll(
          ce,
          '<span class="alikeStr">' + ce + '</span>'
        );
        endStr = endStr.replaceAll(
          ce,
          '<span class="alikeStr">' + ce + '</span>'
        );
      }
      // (offsetRight ? '' : '【偏移量未识别错词】') +
      let html = '';
      if (!offsetRight) {
        html += '【偏移量未识别错词】';
        if (offsetOri == -1) {
          html = '';
        }
      }
      if (offsetOri != offset) {
        html += '【上下文未找到错词】' + [error].join('');
      }

      html = (html ? html + '<br/>' : '') + startStr + targetStr + endStr;
      return html;
    },
    // 表格数据 --多选传参处理
    handleSelectionChange(val) {
      var filterList = [];
      if (val.length) {
        var data = val;
        data.forEach((item) => {
          filterList.push(item.id);
        });
        this.batchList = filterList;
      } else {
        this.batchList = [];
      }
    },

    // 点击按钮 -进行一键确认忽略建议
    handleBatch(val) {
      if (this.batchList.length <= 0) {
        this.$message.warning(`请至少选择一条数据进行操作!`);
        return;
      } else {
        let sendObj = {
          articleCheckIds: this.batchList,
          auditStatus: val,
        };
        this.$api.surveyData.auditArticlecheck(sendObj).then((res) => {
          if (res.code === 0) {
            this.$message.success('操作成功');
            this.searchFn();
            this.getOrderTreeList();
          } else {
            console.log('出现错误');
          }
        });
      }
    },
    customIgnoreCount(row, val) {
      let sendObj = {
        articleCheckIds: [row.id],
        auditStatus: val,
      };
      this.$api.surveyData.auditArticlecheck(sendObj).then((res) => {
        if (res.code === 0) {
          this.$message.success('操作成功');
          this.searchFn();
          this.getOrderTreeList();
        } else {
          console.log('出现错误');
        }
      });
    },
    // 跳转链接
    openWin(url) {
      if (url) {
        window.open(url);
      }
    },
    // 打开抽屉
    openDrawer(row, name) {
      this.drawerIf = name;
      // 详情- auditType-目录，1-机审，2-人审，3-机审+人审，4-挂起(无需监测)，5-描述
      if (name === 'details') {
        this.machinePlusHuman = false;
        this.historyPeople = false;
        // 使用 filter 方法来查找匹配的对象
        const filteredObjects = this.columnLists.filter((obj) =>
          obj.id.includes(row.id)
        );
        if (row.auditType === '3') {
          this.searchParams1.orderCode =
            window.sessionStorage.getItem('orderCode');
            console.log( this.searchParams1.orderCode,'999');
          this.searchParams1.standardId = row.id;
          this.searchParams1.standardDataId = row.standardDataId;
          // row.standardDataId;
        } else {
          this.searchParams.orderCode =
            window.sessionStorage.getItem('orderCode');
          this.searchParams.standardId = row.id;
          this.searchParams.standardDataId = row.standardDataId;
          this.columnList = filteredObjects[0].columnList;
        }
        // 是否分页判断-特殊接口(组件里也进行了特殊判断extInfo包涵url和图片)-其他图片在manualImg中---表格组件说明）
        if (this.hiddenPageArr.includes(row.id)) {
          this.$nextTick(() => {
            this.hiddenPage = true;
          });
        } else {
          this.$nextTick(() => {
            this.hiddenPage = false;
          });
        }
        // 机器-表格
        if (row.auditType === '1') {
          if (row.id === 18) {
            // 特殊接口-站点无法访问分页列表
            this.requestApi = this.$api.surveyData.getWebsiteList;
          } else if (row.id === 111 || row.id === 112) {
            // 特殊接口-扣分-可用性1、2
            this.requestApi = this.$api.surveyData.getOverviewpagelinksList;
          } else if (row.id === 121 || row.id === 122) {
            // 特殊接口-扣分-页面标签1、2
            this.requestApi = this.$api.surveyData.getOverviewcontentList;
          } else {
            this.requestApi = this.$api.surveyData.getOverviewcolumnList;
          }
          //人审 - 审核记录表格
        } else if (row.auditType === '2') {
          // this.historyPeople = true;
          this.requestApi = this.$api.surveyData.getOverviewmanualList;
          //机审+人审--带审核操作的表格
        } else if (row.auditType === '3') {
          this.machinePlusHuman = true;
        }
      } else {
        // 人工审核初始化数据
        this.form = {
          // 监测结果：0-通过，1-不通过
          standardDataId: row.standardDataId,
          orderCode: window.sessionStorage.getItem('orderCode'),
          standardId: row.id,
          monitorStatus: 1,
          score: null,
          remark: null,
          list: [
            {
              valueInfo: null,
              images: [],
            },
          ],
        };
      }
      this.drawer = true;
      this.drawerObj = JSON.parse(JSON.stringify(row));
      if (row.standardContent.length >= 15) {
        this.drawerTitle = `${row.standardContent.slice(0, 15) + '......'}`;
      } else {
        this.drawerTitle = row.standardContent;
      }
    },
    // 人工历史
    historyDetail() {
      this.historyPeople = false;
      this.requestApi = this.$api.surveyData.getOverviewmanualList;
    },
    addItem() {
      let obj = {
        valueInfo: '',
        images: [],
      };
      this.form.list.push(obj);
    },
    delItem(index) {
      if (this.form.list.length === 1) {
        this.$message.warning('至少保留一项');
      } else {
        this.form.list.splice(index, 1);
      }
    },
    getSonInput(val, index) {
      this.form.list[index].images = val;
    },
    onSubmit(formName) {
      let falg = [];
      for (let index = 0; index < this.form.list.length; index++) {
        this.$refs[formName + index][0].validate((valid) => {
          if (valid) {
            falg.push(1);
          }
        });
      }
      if (this.form.monitorStatus === 0) {
        falg.length = this.form.list.length;
      }
      if (falg.length == this.form.list.length) {
        let sendObj = JSON.parse(JSON.stringify(this.form));
        // 人工通过传参处理
        if (sendObj.monitorStatus === 0) {
          sendObj.remark = null;
          delete sendObj.list;
          sendObj.score = 0;
        }
        // 人工不通过-特殊数据传参处理standardId代表每个id指标
        else if (
          sendObj.standardId === 14 ||
          sendObj.standardId === 114 ||
          sendObj.standardId === 118 ||
          sendObj.standardId === 123
        ) {
          // 改属性键名
          this.$set(sendObj, 'urlImagesList', sendObj.list);
          delete sendObj.list;
          if (sendObj.standardId === 14) {
            // 单项人工不要分数
            delete sendObj.score;
          }
          // 处理图片链接
          sendObj.urlImagesList.forEach((item) => {
            // 改属性键名
            if (sendObj.standardId === 123) {
              this.$set(item, 'browserName', item.valueInfo);
            } else {
              this.$set(item, 'url', item.valueInfo);
            }
            if (sendObj.standardId === 114) {
              // 我为政府网站找错-其他页面新增,页面类型： 1-首页，2-其他页面
              this.$set(item, 'pageType', 2);
            }
            delete item.valueInfo;
            if (item.images === '') {
              item.images = [];
            } else if (
              item.images.constructor !== Array &&
              item.images !== ''
            ) {
              item.images = item.images.split(',');
            }
          });
        } else if (sendObj.standardId === 119) {
          // 站内搜索3
          this.$set(sendObj, 'manualImg', sendObj.list[0].images);
          this.$set(sendObj, 'urlImages', { url: sendObj.list[0].valueInfo });
          delete sendObj.list;
        } else {
          this.$set(sendObj, 'manualImg', sendObj.list[0].images);
        }
        this.$api.surveyData.createOverviewmanual(sendObj).then((res) => {
          if (res.code === 0) {
            this.$message.success('操作成功');
            this.drawer = false;
            this.getOrderTreeList();
          } else {
            console.log('出现错误');
          }
        });
      } else {
        this.$message.error('检查校验项');
      }
    },
  },
  computed: {},
  mounted() {
    this.getOrderTreeList();
  },
  async created() {},
};
</script>

<style scoped>
.hiddenContent {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}
.hiddenContent >>> .contextError {
  font-size: 16px;
  font-weight: 600;
  padding: 0 4px;
}
.hiddenContent >>> .contextError {
  color: #f23848;
}
.hiddenContent .contextError >>> .isEqual {
  text-decoration: none;
}
</style>
<style lang="scss">
.alikeStr {
  position: relative;
  padding: 0 4px;
  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #ebb563;
  }
}
.contextError,
.contextSuggest {
  font-size: 16px;
  font-weight: 600;
  padding: 0 4px;
}
.contextError {
  color: #f23848;
  text-decoration: line-through;

  &.isEqual {
    text-decoration: none;
  }
  & + .contextSuggest {
    padding-left: 0;
  }
}
.contextSuggest {
  color: #1ea132;
}
// }
// safari 浏览器 兼容问题解决
::v-deep .el-table__body {
  width: 100%;
  table-layout: fixed !important;
}
</style>
<style lang="scss" scoped>
.main-header {
  width: 100%;
  height: 48px;
  background-color: #fff;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 5px;
}
.icon-left {
  width: 3px;
  height: 15px;
  margin-right: 10px;
  background: url('~@/assets/dot.png') center center no-repeat;
}
.passStatusCss {
  width: auto;
  height: 24px;
  padding: 0 10px;
  // line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
}
.main-bottom {
  width: 100%;
  height: auto;
}
.crad-list {
  width: 100%;
  height: 120px;
  background-color: #fff;
  margin-top: 10px;
  padding: 12px 20px;
  .crad-list-left {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-between;
  }
  .crad-list-right {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: space-between;
  }
}
::v-deep .el-drawer__header {
  color: #333;
  font-weight: 800;
  margin-bottom: 10px;
}
::v-deep .el-drawer__body {
  background-color: #f9fbfe;
  padding-top: 20px;
}
.borderClass {
  border-top: 1px dashed #909399;
}
</style>